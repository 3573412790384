<template>
  <div class="product-cards">
    <div class="title">产品功能</div>
    <div class="divider"></div>
    <ProductCardsInner :cards="cards" class="cards"></ProductCardsInner>
    <!-- <div class="wrapper">
      <div class="inner">
        <div
          class="card-item"
          v-for="(item, index) in cards"
          :key="index"
          @click="gotoDetail(index)"
        >
          <img :src="item.img" alt="" />
          <div class="card-content">
            <div class="card-title">{{ item.title }}</div>
            <div class="card-info">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ProductCardsInner from '@/components/ProductCardsInner.vue';

export default {
  name: 'ProductCards',
  components: {
    ProductCardsInner,
  },
  props: {
    cards: Array,
  },
  methods: {
    gotoDetail(index) {
      const {
        currentRoute: { path },
      } = this.$router;
      const url = `${path}/detail/${index}`;
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped lang="less">
.product-cards {
  text-align: center;
  background: #f5f5f5;
  padding-bottom: @size80;
  .title {
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    margin-top: @size28;
  }
}
.cards {
  margin-top: 48px;
}
.wrapper {
  display: flex;
  justify-content: center;
  margin-top: @size48;
  .inner {
    width: @size1200;
    min-width: 600px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-item {
      width: @size585;
      min-width: 293px;
      height: @size200;
      min-height: 100px;
      border-radius: @size10;
      overflow: hidden;
      position: relative;
      padding-top: @size38;
      padding-left: @size42;
      background: #fff;
      text-align: left;
      margin-bottom: @size30;
      cursor: pointer;
      img {
        width: @size60;
        min-width: 30px;
        float: left;
      }
      .card-content {
        float: left;
        display: inline-block;
        color: #333333;
        text-align: left;
        width: @size400;
        min-width: 200px;
        margin-left: @size38;
        .card-title {
          font-size: @size24;
        }
        .card-info {
          margin-top: @size35;
          font-size: @font16;
          line-height: 1.875;
          text-align: left;
        }
      }
    }
  }
}
</style>
