import product1 from '../assets/images/service/mine/product-1.png';
import product2 from '../assets/images/service/mine/product-2.png';
import product3 from '../assets/images/service/mine/product-3.png';
import product4 from '../assets/images/service/mine/product-4.png';
import product5 from '../assets/images/service/mine/product-5.png';
import product6 from '../assets/images/service/mine/product-6.png';
import topPic from '../assets/images/service/mine/top.jpg';
import card1 from '../assets/images/service/mine/card-1.jpg';
import card2 from '../assets/images/service/mine/card-2.jpg';
import card3 from '../assets/images/service/mine/card-3.jpg';
import back1 from '../assets/images/service/mine/back-1.jpg';
import back2 from '../assets/images/service/mine/back-2.jpg';
import back3 from '../assets/images/service/mine/back-3.jpg';
import detail11 from '../assets/images/service/mine/detail-1-1.png';
import detail12 from '../assets/images/service/mine/detail-1-2.png';
// import detail13 from '../assets/images/service/mine/detail-1-3.png';
import detail21 from '../assets/images/service/mine/detail-2-1.png';
import detail22 from '../assets/images/service/mine/detail-2-2.png';
import detail31 from '../assets/images/service/mine/detail-3-1.png';
import detail32 from '../assets/images/service/mine/detail-3-2.png';
import detail41 from '../assets/images/service/mine/detail-4-1.png';
import detail42 from '../assets/images/service/mine/detail-4-2.png';
import detail51 from '../assets/images/service/mine/detail-5-1.png';
import detail52 from '../assets/images/service/mine/detail-5-2.png';
import detail53 from '../assets/images/service/mine/detail-5-3.png';
import detail54 from '../assets/images/service/mine/detail-5-4.png';
import detail61 from '../assets/images/service/mine/detail-6-1.png';
import detail62 from '../assets/images/service/mine/detail-6-2.png';
import detail63 from '../assets/images/service/mine/detail-6-3.png';
import detail64 from '../assets/images/service/mine/detail-6-4.png';
import detail65 from '../assets/images/service/mine/detail-6-5.png';
import detail67 from '../assets/images/service/mine/detail-6-7.png';
import detail68 from '../assets/images/service/mine/detail-6-8.png';
// import { modalExtra } from './constants';

const cards = [
  {
    img: card1,
    title: '发展趋势',
    // extra: modalExtra,
    back: back1,
    info: '面对煤炭工业高质量发展的要求，和实现安全高效绿色开采和更加清洁高效利用的发展目标，八部委联合下发《关于加快煤矿智能化发展的指导意见》，对煤炭智能化建设提出了明确要求。',
  },
  {
    img: card2,
    title: '困境与挑战',
    info: '环境人员复杂、整体空间大、安全数据不直观，传统技术手段无法应对当前智能化新需求，管理人员无法及时掌握井下实时数据，难以针对发现的问题作出及时应对。',
    // extra: modalExtra,
    back: back2,
  },
  {
    img: card3,
    title: '应用',
    // extra: modalExtra,
    back: back3,
    info: '以井下5G融合通信为基础，以智能安全监控平台、综合管控平台为工具，进行采掘机运通全面智能化改造升级，提升工作效率，降低生产风险，确保人员安全。',
  },
];

const products = [
  {
    img: product1,
    title: '智能煤矿综合管控',
    info: '对井下环境安全、人员位置等安全生产实时信息及生产执行、经营管理、分析决策等矿井信息化系统进行综合集成与可视化展示',
    detail: [
      {
        type: 'title',
        title: '智能煤矿综合管控解决方案',
      },
      {
        image: detail11,
        imageTip: '智能煤矿综合管控系统架构',
      },
      {
        value:
          '综合管控平台可与地面集中控制中心在硬件、场地部署上进行结合，其存储设备、工作站、主控制DCS柜、UPS不间断电源等设备，及视频信息管理应用平台、LCD拼接屏、视频流直写存储显示大屏可复用。其中基于统一I/O采集服务设计与实现，包括自主适配标准工控设备、非标准设备系统、VOIP语音设备系统和流媒体视频监控等设备系统的接入和集成，VOIP语音设备系统和流媒体视频监控在集控中心设计中已包含，管控平台在设计上具有冗余采集和容错机制。',
      },
      {
        value:
          '综合管控平台与地面集控系统互联互通，在地面集控系统对“采、掘、机、运、通”等主要生产环节远程集控基础上，对井下环境安全、人员位置等安全生产实时信息进行综合集成与可视化展示。对生产执行、经营管理、分析决策等矿井信息化系统进行综合集成与可视化展示。',
      },
      {
        image: detail12,
        imageTip: '界面示意图',
      },
      {
        value: '系统采用分布式MySQL持久化集群数据库，结合Redis缓存数据库和Mongo DB持久化，实时采集和解析通过分布式消息队列Rabbit MQ传输的DCS、PLC等数据，达到更高效的存储和数据分析效率。采用Redis和Mongo DB缓存数据库，提升了数据库的稳定性实现数据的负载均衡。并支持对离线数据(非实时、人工等)的处理能力，可通过U盘、移动硬盘等方式拷贝至服务器，运维人员将对数据进行处理分析，集成到整个系统中。支持基于虚拟化技术的应用平台，应用软件在虚拟化平台中各自独立部署运行，可以通过应用平台进行互联互通，具备流程协同、消息推送等功能。',
      },
      {
        value: '本系统从网络部署到运维管理，针对防火墙、入侵检测、病毒防范、数据加密等系统分别采用部署安全管理运维区边界防火墙、防病毒系统、漏洞扫描系统、数据库防火墙和数据加密系统，确保保障物理主机和数据的安全可靠，不受到任何病毒和外界因素的影响；遇到紧急情况时，能及时进行主从切换，保障系统的安全可靠运行。',
      },
      {
        type: 'h2',
        title: '智能煤矿综合管控平台部分功能：',
      },
      {
        type: 'list',
        list: [
          '数据中心管理',
          '安全生产实时综合信息',
          '三维建模与可视化平台',
          '生产计划与生产执行管理',
          '装备运行状态及工况监测',
          '装备生命周期管理',
          '能耗管理',
          '成本管理',
          '备品备件管理',
          '设备巡检与维保管理',
          '移动应用服务',
          '分析决策服务',
        ],
      },
    ],
  },
  {
    img: product2,
    title: '智能煤矿安全监控',
    info: '可与综合管控平台结合，根据煤矿矿井地质条件和生产条件对各子系统统一承载，进行人、机、环的安全监测与防护',
    detail: [
      {
        type: 'title',
        title: '智能煤矿安全监控解决方案',
      },
      {
        image: detail21,
        imageTip: '智能煤矿安全监控系统架构',
      },
      {
        value:
          '智能安全监控平台可与综合管控平台结合，主要技术架构应用、数据中心管理、网络互联方式等与综合管控平台类似。根据煤矿矿井地质条件和生产条件，结合已建设的井下融合通信系统及配套装备，实现煤矿安全监控系统、人员定位管理系统、通信联络系统、智能视频分析系统、智能通风系统、供电监控系统、冲击地压监测系统、水文监测系统等系统的统一承载、共网传输，进行人、机、环的安全监测与防护。',
      },
      {
        image: detail22,
        imageTip: '界面示意图',
      },
      {
        value: '符合验收标准要求建设有安全风险分级管控和隐患排查双重预防体系、 应急管理与应急救援系统或平台功能模块的要求。',
      },
      {
        type: 'h2',
        title: '智能煤矿安全监控系统功能特点：',
      },
      {
        type: 'list',
        list: [
          '具备完善的灾害感知预警系统，实现多种监测数据的统一传输和分类存储。',
          '矿井环境参数的实时监测信息具有与人员单兵装备进行实时互联的功能。',
          '井下重点区域的安全状态实时评估及预警信息具有与人员单兵装备进行实时互联的功能。',
          '具有监测数据的实时分析功能，并具有对安全状态进行实时评估的功能。',
          '能根据灾害监测与评估信息，预测事故发生的可能性。',
          '能根据灾害监测与评估信息，自动调用或制定相应的灾害防治措施或应急预案。',
          '具有完善的安全风险分级管控工作体系，并实现信息化管理。',
        ],
      },
    ],
  },
  {
    img: product3,
    title: '智能煤矿融合通信',
    info: '在满足5G传输要求的基础上，可将原有工业环网接入，并支持Wi-Fi6、NB-IoT（窄带物联网）等扩展接入',
    detail: [
      {
        type: 'title',
        title: '智能煤矿融合通信解决方案',
      },
      {
        image: detail31,
        imageTip: '融合通信示意',
      },
      {
        value:
          '煤矿智能化是煤炭工业高质量发展的核心技术支撑，将人工智能、工业物联网、云计算、大数据、机器人、5G通信技术等与现代煤炭开发利用深度融合，形成全面感知、实时互联、分析决策、自主学习、动态预测、协同控制的智能系统，实现煤矿综采、开拓、运输、通风、洗选、安全保障、经营管理等过程的智能化运行，对于提升煤矿安全生产水平、保障煤炭稳定供应具有重要意义。',
      },
      {
        value:
          '国家煤炭行业标准《安全高效现代化矿井技术规范》（MT/T 1167-2019）要求“应建设有线通信系统、无线通信系统、广播通信系统等，并实现矿井通信、调度、信息管理、安全保障、应急避险等功能的集中统一调度”。',
      },
      {
        value:
          '智能煤矿融合通信系统在满足5G传输要求的基础上，将原有工业环网接入，并支持Wi-Fi6、NB-IoT（窄带物联网）等扩展接入。',
      },
      {
        image: detail32,
        imageTip: '融合通信系统架构',
      },
      {
        value: '运营商提供5G核心网网络，通过SPN传输网连接到矿上，矿上部署MEC，BBU通过光缆和HUB、pRRU连接，井下还可以提供具有煤安证的CPE、5G手机、4K高清摄像仪、智能巡检装置等终端。5G基站布署完成后，5G网络下行峰值速率 300Mbsp 以上,上行峰值速率1100Mbsp 以上，能满足高清视频回传、大数据采集、无人驾驶、AR 远程辅助、无人机巡检、智能机器人、智能工作面等后期应用场景的网络需求。',
      },
      {
        type: 'h2',
        title: '5G融合通信系统功能特点：',
      },
      {
        type: 'list',
        list: [
          '系统采用独特的公网专享+本地备份的网络架构；',
          '可实现井下5G+4G+Wi-Fi6等信号的综合覆盖；',
          '5G无线通信系统具有高带宽、低延时、多连接等特性，适用于多种应用场景；可以支撑VR/4K/AGV等大带宽、低时延、多连接的应用；',
          '采用超级工业环网作为骨干传输网络，带宽高达100G，支持切片技术，从而有效地保证了整个网络系统运行的可靠性、安全性和灵活性及可扩展性。',
          '可以为手机、AI摄像仪、变电所巡检机器人等设备或工作面采集的数据提供接入与传输服务；',
          '可实现无线远程控制以及无人驾驶。',
        ],
      },
    ],
  },
  {
    img: product4,
    title: '智能煤矿精准定位',
    info: '采用UWB定位技术，相较于传统定位系统，有功耗低、系统复杂度低、隐秘性好、截获率低、保密性好等优点',
    detail: [
      {
        type: 'title',
        title: '智能煤矿精准定位解决方案',
      },
      {
        image: detail41,
        imageTip: 'UWB原理示意图',
      },
      {
        value:
          '煤矿井下定位系统作为煤矿六大系统之一，对于煤矿人员及生产安全具有重要的作用。传统的定位系统采用的是 RFID 技术，利用射频方式进行非接触双向通信，实现人们对各类物体或设备(人员、物品)在不同状态(移动或静止)下的识别和数据交换。',
      },
      {
        value:
          '随着技术的发展，当前高精度室内定位的技术是 UWB 定位系统。超宽带（UWB）是一种无线载波通信技术，它不采用传统的正弦载波，而是利用纳秒级的非正弦波脉冲传输数据，其所占的频谱范围很宽，可以从 MHz 至数 GHz。UWB 系统可以在信噪比很低的情况下工作，并且 UWB 系统发射的功率谱密度也非常低，几乎被湮没在各种电磁干扰和噪声中， 故具有功耗低、系统复杂度低、隐秘性好、截获率低、保密性好等优点。',
      },
      {
        image: detail42,
        imageTip: '精准定位系统组成示意图',
      },
      {
        value: 'UWB定位技术属于无线定位技术的一种，定位原理按其测量参数的不同，可分为基于接收信号强度法、基于接收信号时间法和基于接收信号角度法。基于接收信号强度法是由测量结点问能量的情况来估计距离；基于接收信号时间法是由接收信号的传播时间来估计距离；而基于接收信号角度法是测量未知结点和参考结点间的角度来估计位置。',
      },
      {
        value: 'UWB定位技术可实现煤矿井下作业人员进出的有效识别和监测监控，使管理系统充分体现“人性化、信息化和高度自动化”，实现数字矿山的目标。',
      },
    ],
  },
  {
    img: product5,
    title: '智能煤矿无人值守',
    info: '自动监视、远程控制、自动巡检和自动数据记录，能够进行设备运维分析，协助操作人员解决故障问题',
    detail: [
      {
        type: 'title',
        title: '智能煤矿无人值守解决方案',
      },
      {
        type: 'h2',
        title: '固定场所无人值守智能管理系统架构',
      },
      {
        image: detail51,
        imageTip: '固定场所无人值守系统架构示意图',
        top10: true,
      },
      {
        value: '无人值守智能管理实现对通风机房、水泵房、变电所、空压机房、等井上下机房硐室自动监视、远程控制、自动巡检和自动数据记录，系统操作流程符合矿上的管理流程规定，具备大型设备技术资料数据库，能够进行设备运维分析，协助操作人员解决故障问题。',
      },
      {
        type: 'h2',
        title: '1．煤矿变电所无人值守智能管理系统',
        top50: true,
      },
      {
        image: detail52,
        imageTip: '变电所无人值守管理系统示意图',
        top10: true,
      },
      {
        value: '煤矿变电所无人值守智能管理系统可以实现数据采集、运行状态监视、电量计量和考核、安全闭锁、远程集控和自动控制，存储、查询和统计整个系统的运行数据、运行记录、故障报警记录等信息，并实现远程监视设备运行情况，远程操控井下设备的运行和试验，实现故障预警保护、故障定位处理和分析。',
      },
      {
        type: 'h2',
        title: '2．水泵房无人值守智能化管理系统',
        top50: true,
      },
      {
        image: detail53,
        imageTip: '水泵房无人值守管理系统示意图',
        top10: true,
      },
      {
        value: '水泵房无人值守智能化管理系统可对井下排水系统进行集中监控，通过各种先进可靠的传感器、保护装置、电动执行器等监测煤矿井下排水系统各设备的工作状态、实现井下水泵排水系统的自动控制，使水泵排水系统安全可靠、节能高效、经济合理的运行，从而实现减人增效、安全生产的目的。',
      },
      {
        type: 'h2',
        title: '3．主通风无人值守智能化管理系统',
        top50: true,
      },
      {
        image: detail54,
        imageTip: '通风机房无人值守管理系统示意图',
        top10: true,
      },
      {
        value: '系统采用全局自动调控的方法，建立全矿井通风调节模型，将主要通风机、局扇风机、风门、风窗等风量调节设备进行远程集中控制。实现主扇、局扇根据需求自动调风，其中主扇风机能够自动反风，风门、风窗实现远程控制及监测。',
      },
      {
        type: 'h2',
        title: '系统功能：',
      },
      {
        type: 'list',
        list: [
          '实时监测通风系统参数，主要通风机性能参数，电动机的电器参数；',
          '实时监测主要通风机的全压、静压、风量、出口风速；',
          '实时监测主要通风机电动机的电器参数，电流、电压、功率因数、有功功率、无功功率；',
          '实时监测主要通风机的轴承温度，电动机定子绕组温度；',
          '实时监测主要通风机配用电动机垂直及水平振动；',
          '实时监测主要通风机配套设备的运行状态；',
          '可以根据需要实现“就地”、“远程”、“手机APP”启停主要通风机；',
          '实时报警，可以设定对应的数据报警值，超标报警；',
          '数据实时显示，存储、查询、打印、报表。',
        ],
      },
    ],
  },
  {
    img: product6,
    title: '智能化掘进',
    info: '主要包括掘进机控制、锚杆钻车控制、自移机尾控制及掘进后配套控制四大部分',
    detail: [
      {
        type: 'title',
        title: '智能化掘进解决方案',
      },
      {
        image: detail61,
        imageTip: '智能化掘进示意图',
      },
      {
        value: '“智能化掘进系统”主要包括掘进机控制、锚杆钻车控制、自移机尾控制及掘进后配套控制四大部分',
      },
      {
        type: 'h2',
        title: '掘进机智能控制系统',
        center: true,
      },
      {
        image: detail62,
        imageTip: '掘进机智能控制系统示意图',
      },
      {
        value: '在矿用 WIFI 无线通信系统基础上，实现掘进机的智能远程控制功能，具备自主导航、精准定位、坡度追踪、自动截割、超前地质动态显示、智能控制、工况监测、故障诊断、环境监测、危险区域人员识别、一键启停等功能，实现复杂地质条件下的岩巷及半煤岩巷自动化掘进，从而在少人、甚至无人操作下，完成高效、安全的井下掘进作业，最终实现煤矿井下掘进工作的全智能化、无人化目标。',
      },
      {
        image: detail63,
        imageTip: '掘进机智能控制界面示意图',
        top10: true,
      },
      {
        value: '在智能模式下可以无人操作自动截割。需要人干预的情况下，也可以选择遥控模式进行。',
      },
      {
        image: detail64,
        imageTip: '超前探测数据呈现示意图',
        top10: true,
      },
      {
        value: '该系统同时可实现对掘进面前方 100 米的超前探测数据，包括断层、含水量、瓦斯、顶底板岩性、顶底板厚度等信息进行实时动态呈现。',
      },
      {
        type: 'h2',
        title: '锚杆钻车智能控制系统',
        center: true,
      },
      {
        image: detail65,
        imageTip: '锚杆钻车智能控制',
      },
      {
        value: '在结构和功能上采用了整体升降的工作平台，能够根据巷道高度情况通过平台升降使作业人员进行上下调整，从而很方便的实现巷道内不同高度、不同角度锚杆锚索孔、探测孔的机械化作业施工。本钻车具有钻进功能全面、安全性好、工作效率高、工人劳动强度低等特点，尤其是工人在使用本钻车进行锚杆锚索支护作业时能大大缩短支护作业时间，提高支护效率和支护质量。本钻车结构合理、机构紧凑、运转灵活、操作方便。',
      },
      {
        type: 'h2',
        title: '智能自移机尾',
        center: true,
      },
      {
        image: detail67,
        imageTip: '智能自移机尾示意图',
        top10: true,
      },
      {
        value: '在原 DSJ1OO／63／2×75 伸缩皮带机机尾的基础上，反复进行了研讨、测绘、设计。对原机尾机架进行了彻底的改造和完善。研发了带式输送机用自移机尾。突破了锚固拉移方式，该机尾机架装置用于综掘机二运皮带机与后运带式输送机尾的快速推移和搭接，除尘装置同时布置在机架上，随机架一起移动，同时该装置可调节皮带跑偏，实现转载机和机尾的自行前移和校正。提高劳动生产率。使用安全、性能高、灵活可靠。',
      },
      {
        type: 'h2',
        title: '掘进后配套控制系统',
        center: true,
      },
      {
        image: detail68,
        imageTip: '掘进后配套控制系统示意图',
        top10: true,
      },
      {
        type: 'h2',
        title: '掘进后配套控制系统主要集控组成：',
      },
      {
        type: 'list',
        list: [
          '顺槽皮带集控',
          '局部通风机集控',
          '潜水泵集控',
          '除尘风机集控',
          '供配电集控',
        ],
      },
    ],
  },
];

export default {
  headerIndex: 2,
  childIndex: 0,
  title: '智能矿山解决方案',
  info: '致力于提升矿山智能化水平，以科技进步推动煤矿安全及高效生产',
  breadcrumb: ['首页 >', '产品服务 >', '智能矿山解决方案'],
  products,
  topPic,
  cards,
  detailRouterName: 'SmartMineSolutionDetail',
  detailTitle: '方案概述',
};
