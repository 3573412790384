<template>
  <div class="trust-system-solution">
    <Header theme="white" :currentIndex="headerIndex" :childIndex="childIndex" />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumb" />
    <ThreeCards :cards="cards" />
    <ProductCards :cards="products" />
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import ThreeCards from '@/components/ThreeCards.vue';
import ProductCards from '@/components/ProductCards.vue';
import Consult from '@/components/Consult.vue';
import config from '../../utils/trustSystemSolution';

export default {
  name: 'TrustSystemSolution',
  components: {
    Header,
    Footer,
    TopPic,
    ThreeCards,
    ProductCards,
    Consult,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
</style>
