import { render, staticRenderFns } from "./EducationSolution.vue?vue&type=template&id=3727a839&scoped=true&"
import script from "./EducationSolution.vue?vue&type=script&lang=js&"
export * from "./EducationSolution.vue?vue&type=script&lang=js&"
import style0 from "./EducationSolution.vue?vue&type=style&index=0&id=3727a839&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3727a839",
  null
  
)

export default component.exports