<template>
  <div class="smart-campus-solution">
    <Header theme="white" :currentIndex="headerIndex" />
    <TopPic
      :img="topPic"
      :title="title"
      :info="info"
      :breadcrumb="breadcrumb"
    />
    <div class="intro">
      千沐科技为贯彻落实习近平总书记关于人才工作的重要论述和全国教育大会精神，
      加快推进教育信息化高质量发展，积极发展“互联网+教育”，切实培养中国煤炭行业人才，
      建设和运营了“中国煤炭教育赋能·云平台”（以下简称“赋能云平台”）。
      以1+2形式（赋能云平台网站+赋能云职道/赋能云课堂小程序）打通各终端，提供全场景自主式教育培训平台。
    </div>
    <div class="pic-intro">
      <img src="" alt="介绍" />
      <div class="pic-intro-info">
        <p>
          赋能云平台于2019年9月正式发布上线，运营近两年来，千沐科技不断以大数据技术为基础，
          对赋能云平台进行煤炭行业职业赋能课程体系建设，完成覆盖行业
          20余板块，万余课时课程的上线，并与商汤科技共研共建智能化人才培养体系，从通识到进阶，
          从理论到实际1000+课时。现已实现在线导入学号近60万，10余万在校学生提供职业技能及矿业行业认证课程学习和实训。
        </p>
        <p>
          同时，赋能云平台使用大数据记录其行为过程和档案，将学生基本信息和其在平台的赋能行为进行数据化、标签化，
          并对数据进行关联、建库，开展智能化、个性化赋能服务。学生可借助平台在线学习专业课程、认证课程，
          并在完成相应课程后在线申请证书认证。
        </p>
      </div>
    </div>
    <div class="product-pro"></div>
    <ProductCards :cards="products" />
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import ProductCards from '@/components/ProductCards.vue';
import Consult from '@/components/Consult.vue';
import config from '../../utils/educationSolution';

export default {
  name: 'EducationSolution',
  components: {
    Header,
    Footer,
    TopPic,
    ProductCards,
    Consult,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
.intro {
  background: #f5f5f5;
  padding-top: @size70;
  padding-bottom: @size70;
  display: flex;
  justify-content: center; /* 水平居中 */
  font-size: @font16;
  line-height: 1.5;
  color: #3e3a39;
  text-align: left;
}
.pic-intro {
  background: #fff;
  display: flex;
  justify-content: center; /* 水平居中 */
  padding-top: @size60;
  padding-bottom: @size60;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  img {
    width: @size580;
    min-width: 290px;
    margin-right: @size40;
  }
  .pic-intro-info {
    color: #3e3a39;
    line-height: 1.75;
    font-size: @font14;
    text-align: left;
    p {
      margin-bottom: 20px;
    }
  }
}
.product-pro {
  height: @size100;
  min-height: 50px;
  background: #f5f5f5;
}
</style>
