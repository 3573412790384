import topPic from '../assets/images/service/trust/top.jpg';
import card1 from '../assets/images/service/trust/card-1.jpg';
import card2 from '../assets/images/service/trust/card-2.jpg';
import card3 from '../assets/images/service/trust/card-3.jpg';
import product1 from '../assets/images/service/trust/product-1.png';
import product2 from '../assets/images/service/trust/product-2.png';
import product3 from '../assets/images/service/trust/product-3.png';
import back1 from '../assets/images/service/trust/back-1.jpg';
import back2 from '../assets/images/service/trust/back-2.jpg';
import back3 from '../assets/images/service/trust/back-3.jpg';
import detail11 from '../assets/images/service/trust/detail-1-1.png';
import detail21 from '../assets/images/service/trust/detail-2-1.png';
import detail22 from '../assets/images/service/trust/detail-2-2.png';
import detail23 from '../assets/images/service/trust/detail-2-3.png';
import detail24 from '../assets/images/service/trust/detail-2-4.png';
import detail31 from '../assets/images/service/trust/detail-3-1.png';
import detail32 from '../assets/images/service/trust/detail-3-2.png';
import detail33 from '../assets/images/service/trust/detail-3-3.png';
import detail34 from '../assets/images/service/trust/detail-3-4.png';
import detail35 from '../assets/images/service/trust/detail-3-5.png';

const cards = [
  {
    img: card1,
    title: '初心和使命',
    back: back1,
    info: '助力企业实现业务系统虚拟账号升级为真实可信的数字身份，为组织的全程电子化保驾护航',
  },
  {
    img: card2,
    title: '产品和服务',
    info: '涵盖数字身份认证系统、数字证书业务、云服务等多个分类，快速实现身份认证数字身份在线管理',
    back: back2,
  },
  {
    img: card3,
    title: '相关政策',
    back: back3,
    info: '符合《中华人民共和国电子签名法》及国家标准委公布的各项标准',
  },
];

const products = [
  {
    img: product1,
    title: '数字身份认证管理系统',
    info: '安全、便捷、合法、有效的数字身份产品，通过部署e-dims，将所有应用系统的虚拟账号升级为真实可信的数字身份',
    detail: [
      {
        title: 'e-dims数字身份认证管理系统',
        type: 'title',
      },
      {
        value:
          'e-dims是安全、便捷、合法、有效的数字身份产品，组织可以通过部署e-dims，将所有应用系统的虚拟账号升级为真实可信的数字身份。',
      },
      {
        image: detail11,
        imageTip: 'e-dims架构图',
      },
      {
        title: 'e-dims的特点：',
        type: 'h2',
      },
      {
        type: 'list2',
        list: [
          {
            title: '支持自主颁发数字身份',
            intro: [
              '除了可以对接第三方CA机构，e-dims更可支持客户自主颁发数字身份，从而低成本的将组织内部各个应用系统的虚拟账号升级为真实可信的数字身份。',
            ],
          },
          {
            title: '支持面向多应用系统分别对数字身份进行授权',
            intro: [
              '从岗位权责出发，客户可以针对不同应用系统对不同岗位、角色的人员进行授权，从而满足组织对应用权限进行精细化管理的需求。',
            ],
          },
          {
            title: '支持集成多种架构的应用系统和客户端',
            intro: [
              '得益于开箱即用的集成服务套件，e-dims可以跨平台、夸语言支持各类应用程序，支持PC客户端和基于浏览器应用，也支持将e-dims与移动端APP、（企业）微信、小程序无缝集成。',
            ],
          },
          {
            title: '支持应用报表与追溯审计',
            intro: [
              '通过可信日志技术，e-dims系统可以确保数字身份的申请、颁发、应用的所有操作过程都有可信记录，并通过可视化的界面进行分析与审计。',
            ],
          },
        ],
      },
    ],
  },
  {
    img: product2,
    title: '教育行业数字安全体系解决方案',
    info: '结合《电子签名法》、《智慧校园总体架构》，提供符合教育体系的密码应用支撑能力',
    detail: [
      {
        title: '教育行业数字安全体系解决方案',
        type: 'title',
      },
      {
        type: 'h2',
        title: '电子成绩单解决方案',
      },
      {
        image: detail21,
        imageTip: '电子成绩单服务系统示意图',
      },
      {
        value: '本解决方案结合《电子签名法》的要求，基于合法第三方电子认证服务机构，上海CA中心为高校提供合法电子认证服务及相关成熟产品，为高校可信电子成绩单的生成、应用、归档提供全面的技术支撑，促进业务安全可靠开展。',
      },
      {
        image: detail22,
        imageTip: '成绩单生成流程示意图',
      },
      {
        type: 'list',
        list: [
          '依据电子成绩单样式模板，生成PDF格式文件，完成版式化处理；',
          '对PDF文件加盖电子签章、时间戳等可信特征，完成可信化处理，生成可信电子成绩单。',
          '由合法第三方电子认证服务机构为高校教务处签发的单位数字证书，确保电子成绩单签发机构身份真实有效。',
        ],
      },
      {
        image: detail23,
        imageTip: '成绩单验证流程示意图',
      },
      {
        type: 'list',
        list: [
          '上传可信电子成绩单至高校官网提供的验证平台中，进行真伪验证；',
          '官方验证平台进行电子签章验证及可信时间验证。',
        ],
      },
      {
        type: 'h2',
        title: '智慧校园密码服务支撑体系',
      },
      {
        image: detail24,
        imageTip: '密码服务支撑体系架构图',
      },
      {
        value: '依托2018年6月，国家标准委公布《智慧校园总体架构》 。',
      },
      {
        value: '为符合教育部对密码应用体系的总体规划和顶层设计，依托传统密码技术实现技术融合，构建教育密码资源池，提供符合教育体系的密码应用支撑能力。',
      },
      {
        type: 'h2',
        title: '教育行业其他解决方案',
      },
      {
        type: 'list',
        list: [
          '可信电子成绩单解决方案',
          '在线教育行业电子合同解决方案',
          '校内系统账户实名解决方案',
          '教育管理统一认证安全解决方案',
          '电子毕业证 / 学位证解决方案',
          '校园网信任体系建设安全解决方案',
        ],
      },
    ],
  },
  {
    img: product3,
    title: '大家签电子签署平台',
    info: '可靠、安全、易用、便捷的在线电子签署平台，满足随时随地处理合同、公函等文件盖章和签署需求',
    detail: [
      {
        type: 'h2',
        title: '大家签电子签署平台简介',
      },
      {
        image: detail31,
        imageTip: '大家签电子签署平台示意图',
      },
      {
        value: '大家签电子签署平台，是自主设计、建设和运营的电子签署全生命周期服务平台。',
      },
      {
        value: '大家签以上海CA独有的自主发明专利技术和牵头制定的国家标准规范为支撑，综合集成可信的身份认证、合法的数字证书、可靠的电子签名、权威性的时间戳、完整的电子证据等手段，作为可信第三方专注于面向互联网和移动网络环境下信息化应用参与各方提供电子签署服务和系统，满足您随时随地处理合同、协议、文书、公函等文件盖章和签署需求。',
      },
      {
        type: 'h2',
        title: '大家签电子签署平台功能亮点',
      },
      {
        image: detail32,
        imageTip: '与传统纸质合同对比',
      },
      {
        image: detail33,
        imageTip: '大家签电子签署平台功能亮点',
      },
      {
        type: 'h2',
        title: '大家签电子签署平台应用场景',
      },
      {
        image: detail34,
        imageTip: '应用场景示意图',
      },
      {
        image: detail35,
        imageTip: '灵活多样的部署方式',
      },
    ],
  },
];

export default {
  headerIndex: 2,
  childIndex: 3,
  topPic,
  title: '数字安全体系解决方案',
  info: '助力企业实现业务的数字身份，为企业全程电子化及网络安全保驾护航',
  breadcrumb: ['首页 >', '产品服务 >', '数字安全体系解决方案'],
  cards,
  products,
  detailRouterName: 'TrustSystemSolutionDetail',
  detailTitle: '方案概述',
};
