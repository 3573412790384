import product1 from '../assets/images/service/education/product-1.png';
import product2 from '../assets/images/service/education/product-2.png';
import product3 from '../assets/images/service/education/product-3.png';
import product4 from '../assets/images/service/education/product-4.png';
import topPic from '../assets/images/service/education/top.jpg';

const products = [
  {
    img: product1,
    title: '技能学习',
    info: '支持视觉和传感器数据快速训练和部署，可对接原有系统/报警/控制装置',
  },
  {
    img: product2,
    title: '职业规划',
    info: '支持视觉和传感器数据快速训练和部署，可对接原有系统/报警/控制装置',
  },
  {
    img: product3,
    title: '心理咨询',
    info: '支持视觉和传感器数据快速训练和部署，可对接原有系统/报警/控制装置',
  },
  {
    img: product4,
    title: '校园招聘',
    info: '支持视觉和传感器数据快速训练和部署，可对接原有系统/报警/控制装置',
  },
];

export default {
  headerIndex: 2,
  img: topPic,
  title: '教育培训解决方案',
  info: '为应对安全生产、管理效率低下、人员流失等诸多问题的挑战。顺应加快煤矿少人、无人化、开采智能化建设的行业发展趋势，千沐科技联合中科院上海高研院及行业院校共建共研，推出智慧矿山新型实用技术及行业解决方案。',
  breadcrumb: ['首页 >', '产品服务 >', '教育培训解决方案'],
  products,
  topPic,
};
