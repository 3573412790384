<template>
  <div class="smart-mine-solution">
    <Header
      theme="white"
      :currentIndex="headerIndex"
      :childIndex="childIndex"
    />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumbDetail" />
    <DetailTab
      :tabItems="products"
      :currentTab="currentTab"
      :routerName="routerName"
      :onTabChange="onTabChange"
      :title="detailTitle"
    />
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import DetailTab from '@/components/DetailTab.vue';
import Consult from '@/components/Consult.vue';
import config from '../../utils/smartCampusSolution';

export default {
  name: 'SmartCampusSolutionDetail',
  components: {
    Header,
    Footer,
    TopPic,
    DetailTab,
    Consult,
  },
  created() {
    this.onTabChange(this.currentTab);
  },
  data() {
    return {
      ...config,
      currentTab: parseInt(this.$router.currentRoute.params.id, 10),
      breadcrumbDetail: [],
    };
  },
  methods: {
    onTabChange(index) {
      this.breadcrumbDetail = [...this.breadcrumb, this.products[index].title];
    },
  },
};
</script>
<style lang="less" scoped>
</style>
