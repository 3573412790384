import product1 from '../assets/images/service/ai/product-1.png';
import product2 from '../assets/images/service/ai/product-2.png';
import product3 from '../assets/images/service/ai/product-3.png';
import product4 from '../assets/images/service/ai/product-4.png';
import product5 from '../assets/images/service/ai/product-5.png';
import product6 from '../assets/images/service/ai/product-6.png';
import topPic from '../assets/images/service/ai/top.jpg';
import card1 from '../assets/images/service/ai/card-1.jpg';
import card2 from '../assets/images/service/ai/card-2.jpg';
import card3 from '../assets/images/service/ai/card-3.jpg';
import detail11 from '../assets/images/service/mine/detail-1-1.jpg';
import back1 from '../assets/images/service/ai/back-1.jpg';
import back2 from '../assets/images/service/ai/back-2.jpg';
import back3 from '../assets/images/service/ai/back-3.jpg';

const cards = [
  {
    img: card1,
    title: '背景',
    extra: '中国共产党第十九次全国代表大会中，习近平总书记明确指出“提高保障和改善民生水平，加强和创新社会治理”要优先发展教育事业，完善职业教育和培训体系，深化产教融合、校企合作，为职业教育的创新发展指明了方向。',
    back: back1,
    info: '完善职业教育和培训体系，深化产教融合、校企合作',
  },
  {
    img: card2,
    title: '建设目标',
    info: '打造高水平专业化人工智能学科以及产教融合示范基地',
    extra: '围绕“完善职业教育和培训体系，深化产教融合、校企合作”的方针政策，打造具有辐射引领作用的高水平专业化人工智能学科以及产教融合示范基地，为数字经济转型升级服务。',
    back: back2,
  },
  {
    img: card3,
    title: '整体规划',
    extra: '以“一个平台、两个中心、三大模块”为体系，全面满足中高职人工智能教学、科研、实训需求，兼顾社会培训功能，打造国内一流的中高职一体化人工智能应用型人才培养体系。',
    back: back3,
    info: '一个平台、两个中心、三大模块',
  },
];

const products = [
  {
    img: product1,
    title: '人工智能人才培养',
    info: '以教学实训平台为基础，突出理论结合实训特色，从通识到进阶，贯彻产教融合理念，培养AI技能型人才',
    detail: [
      {
        value:
          'SenseMining支持视觉和传感器数据快速训练和部署，可对接原有系统/报警/控制装置。采用端边云方案，满足井上井下多种场景实施，即可融合实施也可独立部署。',
      },
      {
        image: detail11,
      },
    ],
    canDetail: false,
  },
  {
    img: product2,
    title: '人工智能通识教育',
    info: '覆盖完整人工智能知识图谱，学习AI思维理解问题，了解AI落地场景和行业应用',
    detail: [],
    canDetail: false,
  },
  {
    img: product6,
    title: '人工智能实训体系',
    info: '以人工智能典型落地场景为基础，结合行业特色挖掘与AI的结合点，建设符合行业发展趋势的实训体系',
    detail: [],
    canDetail: false,
  },
  {
    img: product5,
    title: '人工智能专业建设',
    info: '以人工智能为核心为专业赋能，打造以AI为核心的专业集群，以丰富的AI专业内容打造双高时代的办学特色',
    detail: [],
    canDetail: false,
  },
  {
    img: product3,
    title: '人工智能师资培训',
    info: '快速入门并掌握AI编程等行业技能知识点，资深专家讲座及经验分享，辅导教学实践',
    detail: [],
    canDetail: false,
  },
  {
    img: product4,
    title: '科技创新与大赛',
    info: '针对综合性AI能力进行培训，使用AI工具服务特定场景，配套有潜力的创新创业环境',
    detail: [],
    canDetail: false,
  },
];

export default {
  headerIndex: 2,
  childIndex: 2,
  title: '人工智能产教融合',
  info: '以创新实训助力智能化人才培养，打造完整AI产教融合体系',
  breadcrumb: ['首页 >', '产品服务 >', '人工智能产教融合'],
  products,
  topPic,
  cards,
  detailRouterName: 'AISolutionDetail',
  detailTitle: '方案概述',
};
