<template>
  <div class="detail-tab">
    <div class="tabs">
      <div
        class="tab"
        v-for="(item, index) in tabItems"
        :key="index"
        @click="onChange(index)"
        :class="currentTab == index ? 'active' : ''"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="wrapper">
      <div class="content">
        <div class="title">
          {{ title }}
        </div>
        <div class="divider"></div>
        <div
          class="content-item"
          v-for="(info, infoIndex) in tabItems[currentTab].detail || []"
          :key="infoIndex"
        >
          <div v-if="info.type === 'list'" class="ul">
            <p class="li" v-for="(l, lIndex) in info.list" :key="lIndex">
              {{ l }}
            </p>
          </div>
          <p v-if="info.value" class="line">
            <span v-if="info.boldPre" class="bold-pre">{{ info.boldPre }}</span
            >{{ info.value }}
          </p>
          <img
            :src="info.image"
            v-if="info.image"
            class="image"
            :class="getImageClass(info)"
            :style="info.style || {}"
          />
          <div v-if="info.type === 'title'" class="content-title">
            {{ info.title }}
          </div>
          <div v-if="info.imageTip" class="image-tip">{{ info.imageTip }}</div>
          <p
            v-if="info.type === 'h2'"
            class="h2"
            :class="getH2Class(info)"
            :style="info.style || {}"
          >
            {{ info.title }}
          </p>
          <div v-if="info.type === 'list2'" class="ul2">
            <div v-for="(l, lIndex) in info.list" :key="lIndex">
              <p class="li bold">{{ l.title }}</p>
              <p
                class="li-detail"
                v-for="(it, itIndex) in l.intro"
                :key="itIndex"
              >
                {{ it }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailTab',
  props: {
    tabItems: Array,
    routeName: String,
    onTabChange: Function,
    title: String,
  },
  data() {
    return {
      currentTab: parseInt(this.$router.currentRoute.params.id, 10),
    };
  },
  created() {
    return this.$router;
  },
  methods: {
    onChange(index) {
      this.currentTab = index;
      this.$router.push({ name: this.routeName, params: { id: index } });
      if (this.onTabChange) {
        this.onTabChange(index);
      }
    },
    getMarginTop(item) {
      if (item.top25) {
        return 'margin-top-25';
      }
      if (item.top50) {
        return 'margin-top-50';
      }
      if (item.top0) {
        return 'margin-top-0';
      }
      if (item.top10) {
        return 'margin-top-10';
      }
      return '';
    },
    getMarginBottom(item) {
      if (item.bottom10) {
        return 'margin-bottom-10';
      }
      if (item.bottom25) {
        return 'margin-bottom-25';
      }
      return '';
    },
    getImageSize(item) {
      if (item.size250) {
        return 'size-250';
      }
      return '';
    },
    getH2Class(item) {
      const classes = [];
      if (item.center) {
        classes.push('text-center');
      }
      const top = this.getMarginTop(item);
      if (top) {
        classes.push(top);
      }
      const bottom = this.getMarginBottom(item);
      if (bottom) {
        classes.push(bottom);
      }
      return classes.join(' ');
    },
    getImageClass(item) {
      const classes = [];
      if (item.imageTip) {
        classes.push('has-tip');
      }
      const top = this.getMarginTop(item);
      if (top) {
        classes.push(top);
      }
      const bottom = this.getMarginBottom(item);
      if (bottom) {
        classes.push(bottom);
      }
      const size = this.getImageSize(item);
      if (size) {
        classes.push(size);
      }
      return classes.join(' ');
    },
  },
};
</script>
<style scoped lang="less">
.tabs {
  margin-left: @size360;
  width: @size1200;
  min-width: 600px;
  height: @size80;
  min-height: 40px;
  line-height: 4;
  font-size: @font18;
  color: #333;
  display: flex;
  justify-content: space-between;
  .tab {
    cursor: pointer;
  }
  .active {
    border-bottom: @size4 solid #0066dd;
  }
}
.wrapper {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center; /* 水平居中 */
}
.content {
  width: @size1200;
  min-width: 600px;
  text-align: center;
  background: #f5f5f5;
  padding-top: @size80;
  padding-bottom: @size140;
  .title {
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    background: #0066dd;
    margin-bottom: @size50;
    margin-top: @size28;
  }
  p {
    text-align: left;
    color: #3e3a39;
    font-size: 20px;
    line-height: 1.5;
  }
  img {
    border: 1px solid #bfbfbf;
    width: @size1000;
    height: auto;
    margin-top: @size50;
    margin-bottom: @size50;
  }
  .has-tip {
    margin-bottom: 20px;
  }
  .li::before {
    content: '▶';
    margin-left: 40px;
    margin-right: 8px;
  }
  .h2 {
    font-size: 24px;
    font-weight: 700;
  }
  .content-title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #3e3a39;
    margin-bottom: 50px;
  }
  .image-tip {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }
  .text-center {
    text-align: center;
    margin-top: 50px;
  }
  .bold-pre {
    font-weight: 700;
  }
  .margin-top-25 {
    margin-top: 25px;
  }
  .margin-top-50 {
    margin-top: 50px;
  }
  .margin-top-0 {
    margin-top: 0;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-bottom-25 {
    margin-bottom: 25px;
  }
  .margin-bottom-10 {
    margin-bottom: 10px;
  }
  .size-250 {
    width: 250px;
  }
  .bold {
    font-weight: bold;
  }
  .li-detail {
    padding-left: 66px;
  }
}
</style>
