<template>
  <div class="smart-campus-solution">
    <Header theme="white" :currentIndex="headerIndex" :childIndex="childIndex" />
    <TopPic :img="topPic" :title="title" :info="info" :breadcrumb="breadcrumb" />
    <div class="intro">
      <img src="../../assets/images/service/campus/intro.jpg" alt="">
    </div>
    <div class="product-pro"></div>
    <ProductCards :cards="products" />
    <Consult />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TopPic from '@/components/TopPic.vue';
import ProductCards from '@/components/ProductCards.vue';
import Consult from '@/components/Consult.vue';
import config from '../../utils/smartCampusSolution';

export default {
  name: 'SmartCampusSolution',
  components: {
    Header,
    Footer,
    TopPic,
    ProductCards,
    Consult,
  },
  data() {
    return {
      ...config,
    };
  },
};
</script>
<style lang="less" scoped>
.intro {
  margin-top: @size80;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: @size84;
  img {
    width: @size1200;
    min-width: 600px;
  }
}
.product-pro {
  height: @size100;
  min-height: 50px;
  background: #f5f5f5;
}
</style>
