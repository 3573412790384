import product1 from '../assets/images/service/campus/product-1.png';
import product2 from '../assets/images/service/campus/product-2.png';
import product3 from '../assets/images/service/campus/product-3.png';
import product5 from '../assets/images/service/ai/product-5.png';
import product6 from '../assets/images/service/ai/product-6.png';
import topPic from '../assets/images/service/campus/top.jpg';
import detail11 from '../assets/images/service/campus/detail-1-1.png';
import detail12 from '../assets/images/service/campus/detail-1-2.png';
import detail13 from '../assets/images/service/campus/detail-1-3.png';
import detail14 from '../assets/images/service/campus/detail-1-4.png';
import detail15 from '../assets/images/service/campus/detail-1-5.png';
import detail16 from '../assets/images/service/campus/detail-1-6.png';
import detail21 from '../assets/images/service/campus/detail-2-1.png';
import detail22 from '../assets/images/service/campus/detail-2-2.png';
import detail31 from '../assets/images/service/campus/detail-3-1.png';
import detail32 from '../assets/images/service/campus/detail-3-2.png';
import detail41 from '../assets/images/service/campus/detail-4-1.png';
import detail42 from '../assets/images/service/campus/detail-4-2.png';
import detail51 from '../assets/images/service/campus/detail-5-1.png';

const products = [
  {
    img: product5,
    title: '高校数字化管理平台',
    info: '统一门户、统一组织、统一信息、统一消息、统一权限、统一入口，为智慧校园平台打造强有力的基座',
    detail: [
      {
        type: 'title',
        title: '高效数字化管理平台解决方案',
      },
      {
        type: 'h2',
        title: '智慧高校数智平台架构蓝图',
      },
      {
        image: detail11,
      },
      {
        value: '5大核心场景，构建智慧校园新生态',
      },
      {
        type: 'h2',
        title: '统一平台规划，为数字化建设提供强大基座能力',
      },
      {
        value:
          '通过统一门户、统一组织、统一信息、统一消息、统一权限、统一入口、统一API等，为智慧校园平台打造强有力的基座',
      },
      {
        image: detail12,
        top10: true,
      },
      {
        type: 'h2',
        title: '办事流程与信息服务一网通办，师生最多跑一次',
      },
      {
        value:
          '重构业务流程，为师生家校提供统一办事服务平台，支持网上在线办事、政策法规查询、便民信息咨询、师生业务办理等，同时支持公文流转、人事管理、固资管理、费控管理等在线，为师生、家长、校友提供统一办事服务平台',
      },
      {
        image: detail13,
        top10: true,
      },
      {
        type: 'h2',
        title: '掌上轻松办事，随时随地开启智慧校园',
      },
      {
        value:
          '支持移动办公，教师可随时用手机进行调课、查看学生考勤情况；领导可随时查看相关数据信息，及时进行洞察决策',
      },
      {
        image: detail14,
        top10: true,
      },
      {
        type: 'h2',
        title: '集成业务系统，统一身份认证，实现人人通事事通',
      },
      {
        value:
          '满足应用、云服务、物联网、AI、生态系统、移动应用、事件流信息、工具等8大集成需求，统一身份认证，实现校内外人人通、事事通',
      },
      {
        image: detail15,
        top10: true,
      },
      {
        type: 'h2',
        title: '大数据共享，有效指导教学与决策，建设横纵向打通的数据平台',
      },
      {
        value:
          '整合9大业务系统数据，通过36000个关键指标、500多个维模型、900多张定制报表，为学校各项业务提供动态实时数据分析，助力高校教学、管理、创新、学科建设、科研',
      },
      {
        image: detail16,
        top10: true,
      },
    ],
  },
  {
    img: product1,
    title: '校园智能安防',
    info: '周界/重点区域/公共场所/学生宿舍，4道防线维护平安校园，构建以人脸识别为主，校园卡等多种通行介质为一体的统一授权的校园安全管理平台',
    detail: [
      {
        type: 'title',
        title: '校园智能安防解决方案',
      },
      {
        image: detail21,
        bottom10: true,
      },
      {
        value: '打破信息孤岛，建立集信息化、数字化、网络化、智能化为一身的智能安防系统，有效提升高校安防应用水平，对维护高校校园安全稳定、提升安保效率具有重要意义。',
      },
      {
        type: 'h2',
        title: '多位一体的安全防线',
        top50: true,
      },
      {
        value: '构建以人脸识别为主，校园卡等多种通行介质为一体的统一授权的校园安全管理平台',
      },
      {
        image: detail22,
      },
      {
        value: '按照“高清化、网络化、智能化、高集成”安防综合管理系统的设想，以最新信息技术和智能应用为支撑。在信息全面感知和网络无处不在的基础上，全面整合校内外的资源，实现人、物、校区功能系统之间，无缝连接与协同联动的智能自感知、自适应、自优化。',
      },
      {
        value: '从而智能识别校园内所有群体的学习、工作情景和个体的特征，将学校物理空间和数字空间有机衔接起来，建立一个高度智能、全面开放的校园环境。',
      },
      {
        type: 'h2',
        title: '系统具备以下4个特性：',
        top50: true,
      },
      {
        type: 'h2',
        title: '1）高度集成的综合管理平台',
      },
      {
        value: '通过管理平台实现全网统一的安防资源管理，对视频监控、人脸识别、车辆管理、门禁管理、报警管理、消防报警、访客预约管理等系统进行统一管理，实现远程参数配置与远程控制等;通过管理平台实现全网统一的用户和权限管理，满足系统多用户的监控、管理需求，真正做到坐阵指挥中心，掌控全校安防信息。',
      },
      {
        type: 'h2',
        title: '2）高度智能的技防应用系统',
      },
      {
        value: '包括系统前后端都具有深度学习能力，系统能及时发现前端系统的故障并及时告警的自检能力，甚至自动修复“自愈”能力。',
      },
      {
        type: 'h2',
        title: '3）建成快速部署、及时维护的系统',
      },
      {
        value: '通过采用高集成化、模块化设计的设备提高系统部署效率，减少系统调试周期，系统能及时发现前端系统的故障并及时告警，快速响应。',
      },
      {
        type: 'h2',
        title: '4）建成高度整合、充分利旧的系统',
      },
      {
        value: '新建系统能与原有系统高度整合、无缝对接，能充分利用原有监控资源，避免前期投资的浪费。',
      },
    ],
  },
  {
    img: product2,
    title: '车辆通行管理',
    info: '通过视频结构化算法，自动识别保存出入的机动车信息（类型、颜色、型号）并进行筛查跟踪，当车辆出现违停等情况时发出告警',
    detail: [
      {
        type: 'title',
        title: '车辆通行管理解决方案',
      },
      {
        type: 'h2',
        title: '校内车辆识别：',
      },
      {
        image: detail31,
        top10: true,
      },
      {
        type: 'h2',
        title: '功能介绍：',
      },
      {
        value: '1、通过视频结构化算法，自动识别并保存进出园区的机动车的车牌、颜色、车辆类型、车辆型号、通行时间、摄像机点位。',
      },
      {
        value: '2、可根据车辆类型和颜色筛查机动车在园区的抓拍历史记录。',
      },
      {
        type: 'h2',
        title: '功能特色：',
      },
      {
        value: '1、能识别7种车辆类型：轿车、面包车、皮卡、卡车、SUV、巴士、中巴。',
      },
      {
        value: '2、能识别10种车辆颜色：灰、白、红、黑、蓝、绿、棕、黄、紫、粉。',
      },
      {
        value: '3、能识别3979种车辆型号，如沃尔沃-S80。',
      },
      {
        type: 'h2',
        title: '车辆违停告警：',
        top50: true,
      },
      {
        image: detail32,
        top10: true,
      },
      {
        type: 'h2',
        title: '功能介绍：',
      },
      {
        value: '1、在监控画面内绘制机动车违停区域。',
      },
      {
        value: '2、设置违停告警的时间范围。',
      },
      {
        value: '3、设置触发违停告警的时限（如1分钟）。',
      },
      {
        value: '4、当机动车进入违停区域，并且停留时间达到预设的告警时限时，系统推出违停告警信息。',
      },
      {
        type: 'h2',
        title: '功能特色：',
      },
      {
        value: '1、单个监控画面内，支持最多画4个不同位置的违停区域，可分别实现违停告警的推送。',
      },
    ],
  },
  {
    img: product3,
    title: '宿舍管理',
    info: '通过智能终端设备快速拍照上传查寝记录，及时发现学生夜不归宿、晚归、应出未出等情况，提高学生寝室管理工作水平',
    detail: [
      {
        type: 'title',
        title: '宿舍管理解决方案',
      },
      {
        value: '随着学校后勤改革的进一步深入，各学校基本实行了学生公寓化管理，而学生宿舍的管理也由传统的管理模式向后勤智能化管理模式转变。学生宿舍的安全保卫工作，不仅关系到学生宿舍管理人员的稳定和管理制度的执行，而且关系到高效的稳定，关系到学生的人身财产安全。',
      },
      {
        value: '另一方面，学生缺勤如何处理，如何利用智能化手段来实现数字校园，利用人脸识别技术实现学生考勤系统，结合教务的请假信息与学校考勤数据，将每名学生的出寝情况实时掌握，是越来越多学校的改革和发展趋势。',
      },
      {
        image: detail41,
      },
      {
        value: '通过智能终端设备快速拍照上传查寝记录，系统后台能够查询管理人员的检查工作记录，并支持对不合格寝室通报批评，勒令整顿，并可及时发现学生夜不归宿、晚归、早上应出未出(可能生病)等情况。有效提高学生寝室检查管理工作水平，为学校管理者提供便捷工具。',
      },
      {
        image: detail42,
      },
      {
        type: 'h2',
        title: '能力与业务价值：',
      },
      {
        type: 'list',
        list: [
          '领先的人脸识别算法',
          '多类型智能终端集成统一管理',
          '学生刷脸通行',
          '通行记录自动进行大数据分析统计',
          '有效减少查寝工作，智能预警',
        ],
      },
    ],
  },
  {
    img: product6,
    title: '虚拟实训',
    info: '利用虚拟化技术有效解决“高成本”、“高风险”、“高运维”，提高教学效率',
    detail: [
      {
        type: 'title',
        title: '虚拟实训解决方案',
      },
      {
        value: '虚拟仿真实验教学一直是国内高等教育关注和研究的热点，早在国内科技支撑计划中便有虚拟实验教学环境相关课题研究的重点项目。2013年8月，为贯彻落实《关于全面提高高等教育质量的若干意见》精神，决定开展虚拟仿真实验教学中心建设工作，这一决定标志着我国对虚拟仿真实验室的建设进入了规模化、系统化、标准化、可持续发展阶段。2017年7月，国家发文，计划2017~2020年期间建成1000个国家虚拟仿真实验教学项目，覆盖28个学科大类，200多个专业方向。',
      },
      {
        value: '从2015年到2021年，院校越来越认识到通过虚拟仿真实验教学在人才培养过程中的重要性和必要性，原因是传统的实验教学面临3个方面的难题：',
      },
      {
        boldPre: '“高成本”',
        value: '——在传统的实验教学环境，如果涉及大型设备、特种设备、操作复杂、实物成本高、消耗能源大等情况下，开展多班次大批量的实验教学极为不便；',
      },
      {
        boldPre: '“高风险”',
        value: '——当涉及高空、有毒、腐蚀、高温、爆炸等特殊作业环境，存在不安全因素等难题，效果较差；',
      },
      {
        boldPre: '“高运维”',
        value: '——通过传统的文字、图片、视频、讲解的教学方式不利于复杂抽象的理解和观摩，如果进入企业真实现场或还原真实现场，运营管理将是复杂和困难的工作，另外部分涉密单位，为实验教学带来不便。',
      },
      {
        image: detail51,
      },
      {
        value: '面临以上种种困难，将虚拟仿真技术引入实验教学便成为较好的解决方案。虚拟仿真作为一种通用技术，目前已应用于许多领域。基于虚拟仿真技术所开发的虚拟仿真系统，因其所固有的直观性、高效性及经济性，从发展之初就首先被应用到了教育培训领域，尤其对真实实验平台无法开展的具有高危险性、大型综 合的复杂系统以及模拟真实实验教学成本较高的系统，利用虚拟仿真技术可以有效解决当前实验教学中所面临的困难。',
      },
    ],
  },
];

export default {
  headerIndex: 2,
  childIndex: 1,
  topPic,
  title: '智慧校园解决方案',
  info: '夯实学校信息基础与智能管理，进一步提升校园数字化水平',
  breadcrumb: ['首页 >', '产品服务 >', '智慧校园解决方案'],
  products,
  detailRouterName: 'SmartCampusSolutionDetail',
  detailTitle: '方案概述',
};
